<template>
  <div class="page page_settings">
    <esmp-tabs v-model="tabName" @input="onTabChange">
      <esmp-tab-pane
        label="Роли"
        name="roles"
      />

      <esmp-tab-pane
        label="Приоритеты"
        name="priorities"
      />

      <esmp-tab-pane
        label="Статусы"
        name="statuses"
      />

      <esmp-tab-pane
        label="Привилегированные пользователи"
        name="privilege"
      />
    </esmp-tabs>

    <component :is="tabComponent" />
  </div>
</template>

<script>

const Roles = () => import('@/pages/admin/settings/Roles.vue');
const Priorities = () => import('@/pages/admin/settings/Priorities.vue');
const Privilege = () => import('@/pages/admin/settings/Privilege.vue');
const Statuses = () => import('@/pages/admin/settings/Statuses.vue');

export default {
  name: 'Settings',

  components: {
    Statuses, Priorities, Roles, Privilege,
  },

  data() {
    return {
      tabName: 'roles',
      tabComponent: Roles,
    };
  },

  methods: {
    onTabChange(tabName) {
      switch (tabName) {
      case 'statuses':
        this.tabComponent = Statuses;
        break;
      case 'priorities':
        this.tabComponent = Priorities;
        break;
      case 'privilege':
        this.tabComponent = Privilege;
        break;
      case 'roles':
      default:
        this.tabComponent = Roles;
        break;
      }
    },
  },
};
</script>

<style lang="scss">
.settings__error-message {
  color: $error-color;
}
.settings__user-search {
  &__item-email {
    font-size: 12px;
    line-height: 16px;
    color: $color-black-op-50;
    margin-right: 16px;
  }
}
</style>
